import moment from "moment";
import React from "react";
import AirportTransferGroupCard from "../../components/StageOneCards/AirportTransferGroupCard";
import CityPassGroupCard from "../../components/StageOneCards/CityPassGroupCard";
import DinnerGroupCard from "../../components/StageOneCards/DinnerGroupCard";
import HotelGroupCard from "../../components/StageOneCards/HotelGroupCard";
import LimoGroupCard from "../../components/StageOneCards/LimoGroupCard";
import VegasLimoTransferGroupCard from "../../components/StageOneCards/VegasLimoTransferGroupCard";
import PackageDetailsCard from "../../components/StageThreeCards/PackageDetailsCard";
import { EDITABLE_CART_TYPES, SERVICE_STATE, STAGE_ONE_CARDS } from "../../utils/constants";
import "./cartsContainer.scss";

function getStageOneCardLayput(
  packageDetailsId: number,
  cartGroup: string,
  cardDescription: string,
  setPackageOrderWithDetails: any,
  attendeeQty: number,
  decryptedInvoiceId?: number,
  setMissingInfoCards?: React.Dispatch<React.SetStateAction<string[]>>,
) {
  const STAGE_ONE_CARDS = {
    "Airport Transfer": (
      <AirportTransferGroupCard
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cardDescription={cardDescription}
      />
    ),
    Limo: (
      <LimoGroupCard
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cardDescription={cardDescription}
      />
    ),
    "City Pass": (
      <CityPassGroupCard
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        attendeeQty={attendeeQty}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cardDescription={cardDescription}
      />
    ),
    Dinner: (
      <DinnerGroupCard
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        cardDescription={cardDescription}
      />
    ),
    "Vegas Limo Transfer": (
      <VegasLimoTransferGroupCard
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cardDescription={cardDescription}
      />
    ),
    Hotel: (
      <HotelGroupCard
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cardDescription={cardDescription}
      />
    ),
  };
  return STAGE_ONE_CARDS[cartGroup as keyof typeof STAGE_ONE_CARDS];
}

const CartsContainer = ({
  packageOrderWithDetails,
  setModalType,
  setPackageDetailsId,
  setIsModalOpened,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  isCartInfoOpenedId,
  handleOpenDetails,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  isInvoiceArchived,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
}: any) => {
  return packageOrderWithDetails.orderDetails.map(
    (cart: any, cartId: number, orderDetailsArray: any) => {
      const attendeeQty = packageOrderWithDetails.qty;
      const parsedCardData: any = cart.cartDetails && JSON.parse(cart.cartDetails.cartJsonData);
      const prevParsedCartData: any =
        cartId !== 0 && orderDetailsArray[cartId - 1].cartDetails
          ? JSON.parse(orderDetailsArray[cartId - 1].cartDetails.cartJsonData)
          : null;

      let curDate;
      let prevDate;
      if (cart.group === "Airport Transfer") {
        curDate =
          parsedCardData?.transfer1?.date1 || parsedCardData
            ? parsedCardData?.transfer1?.date1 || parsedCardData?.date1 || parsedCardData?.date2
            : null;
      } else {
        curDate = parsedCardData?.date ? parsedCardData.date : null;
      }

      if (
        cartId !== 0 &&
        orderDetailsArray[cartId - 1] &&
        orderDetailsArray[cartId - 1].group === "Airport Transfer"
      ) {
        prevDate =
          prevParsedCartData?.transfer1 || prevParsedCartData
            ? prevParsedCartData?.transfer1?.date1 ||
              prevParsedCartData?.date1 ||
              prevParsedCartData?.date2
            : null;
      } else {
        prevDate = prevParsedCartData?.date ? prevParsedCartData.date : null;
      }
      
      if (cart.group === "Vegas Limo Transfer") {
        curDate =
          parsedCardData?.transfer1?.pickUp1 || parsedCardData
            ? parsedCardData?.transfer1?.pickUp1 || parsedCardData?.pickUp1 || parsedCardData?.pickUp1
            : null;
      } else {
        curDate = parsedCardData?.date ? parsedCardData.date : null;
      }

      if (
        cartId !== 0 &&
        orderDetailsArray[cartId - 1] &&
        orderDetailsArray[cartId - 1].group === "Vegas Limo Transfer"
      ) {
        prevDate =
          prevParsedCartData?.transfer1 || prevParsedCartData
            ? prevParsedCartData?.transfer1?.pickUp1 ||
              prevParsedCartData?.pickUp1 ||
              prevParsedCartData?.pickUp2
            : null;
      } else {
        prevDate = prevParsedCartData?.date ? prevParsedCartData.date : null;
      }

      const IS_SHOW_MISSING_INFO_LABEL =
        cart.type &&
        EDITABLE_CART_TYPES.includes(cart.type) &&
        cart.cartDetails &&
        !isInvoiceArchived &&
        cart.isCardHasMissingInfo;

      const CARD_TITLE = cart.cartDetails?.cardTitle ? cart.cartDetails?.cardTitle : cart.type;

      const CARD_DATE = parsedCardData?.date || parsedCardData?.date1 || parsedCardData?.date2 || parsedCardData?.transfer1?.pickUp1 || parsedCardData?.transfer1?.pickUp2;

      const IS_SHOW_DECLINE_SERVICE_BUTTON =
        !cart.cartDetails?.isServiceDeclined &&
        !(
          cart.cartDetails?.isServiceDeclined === SERVICE_STATE.DECLINED ||
          cart.cartDetails?.isServiceDeclined === SERVICE_STATE.UNDECLINED
        ) &&
        !STAGE_ONE_CARDS.includes(cart.group) &&
        !cart.cartDetails?.cartJsonData &&
        CARD_TITLE !== "Event Details";

      const cardDate =
        parsedCardData?.transfer1?.date1 ||
        parsedCardData?.date ||
        parsedCardData?.date1 ||
        parsedCardData?.date2
          ? moment(
              cart.type === "Airport Transfer"
                ? parsedCardData?.transfer1?.date1 || parsedCardData?.date1 || parsedCardData?.date2
                : parsedCardData.date,
            ).format("dddd, MMMM D")
          : "";
      return (
        <React.Fragment key={cartId}>
          {!cart?.cartDetails?.isPublished &&
          !cart?.cartDetails?.cartId &&
          getStageOneCardLayput(
            cart.packageDetailsId,
            cart.group,
            cart?.cartDetails?.cardDescription,
            setPackageOrderWithDetails,
            attendeeQty,
          ) ? (
            getStageOneCardLayput(
              cart.packageDetailsId,
              cart.group,
              cart?.cartDetails?.cardDescription,
              setPackageOrderWithDetails,
              attendeeQty,
              decryptedInvoiceId,
              setMissingInfoCards,
            )
          ) : (
            <div className="outer-card-container">
              {moment(curDate).format("l") !== moment(prevDate).format("l") ? (
                <span className="card-date mb-2">
                  {parsedCardData?.transfer1?.date1 ||
                  parsedCardData?.transfer1?.pickUp1 ||
                  parsedCardData?.date ||
                  parsedCardData?.date1 ||
                  parsedCardData?.date2
                    ? moment(
                    (cart.group === "Airport Transfer" || cart.group === "Vegas Limo Transfer" )
                      ? parsedCardData?.transfer1?.date1 ||
                        parsedCardData?.transfer1?.pickUp1 ||
                          parsedCardData?.date1 ||
                          parsedCardData?.date2
                      : parsedCardData.date,
                      ).format("dddd, MMMM D")
                    : ""}
                </span>
              ) : null}
              <PackageDetailsCard
                key={cartId}
                cardDate={CARD_DATE}
                isShowMissingInfoLabel={IS_SHOW_MISSING_INFO_LABEL}
                isShowDeclineServiceButton={IS_SHOW_DECLINE_SERVICE_BUTTON}
                cart={cart}
                cardTitle={CARD_TITLE}
                setModalType={setModalType}
                setPackageDetailsId={setPackageDetailsId}
                setIsModalOpened={setIsModalOpened}
                airportTransferType={airportTransferType}
                setAirportTransferType={setAirportTransferType}
                vegasLimoTransferType={vegasLimoTransferType}
                setVegasLimoTransferType={setVegasLimoTransferType}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                handleOpenLearnMore={handleOpenLearnMore}
                isLearnMoreOpenedId={isLearnMoreOpenedId}
                isInvoiceArchived={isInvoiceArchived}
                setPackageOrderWithDetails={setPackageOrderWithDetails}
                parsedCardData={parsedCardData}
              />
            </div>
          )}
        </React.Fragment>
      );
    },
  );
};

export default CartsContainer;
