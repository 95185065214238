import "./provideMissingInfo.scss";

const DeclinedLabel = () => {
  return (
    <div className="d-flex align-items-center provide-missing-info-label-container">
      <div className="exclamation-circle"></div>
      <span className="info-note">Service Declined</span>
    </div>
  );
};

export default DeclinedLabel;
