import moment from "moment";
import { Card, CardHeader, Row, Col, CardBody } from "reactstrap";
import { successMsg } from "../../cache/vars";
import { DECLINE_SERVICE } from "../../queries/vip-workflow";
import { EDITABLE_CART_TYPES, SERVICE_STATE } from "../../utils/constants";
import DeclinedLabel from "../labels/DeclinedLabel";
import MissingInfo from "../labels/MissingInfo";
import PendingLabel from "../labels/Pending";
import RenderCardBody from "./RenderCardBody";
import { useApolloClient } from "@apollo/client";

const PackageDetailsCard = ({
  cardDate,
  isShowMissingInfoLabel,
  isShowDeclineServiceButton,
  cart,
  cardTitle,
  setModalType,
  setPackageDetailsId,
  setIsModalOpened,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  isCartInfoOpenedId,
  handleOpenDetails,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  isInvoiceArchived,
  setPackageOrderWithDetails,
  parsedCardData,
}: any) => {
  const client = useApolloClient();

  return (
    <div className="card-container">
      <div className="pt-3 pb-3">
        <Row className="d-flex flex-column flex-nowrap">
          <Col
            sm={
              cart.type && EDITABLE_CART_TYPES.includes(cart.type) && cart.cartDetails ? "10" : "12"
            }
            className="ml-4 d-flex align-items-center flex-wrap"
          >
            <h1 className="m-0 pr-3">
              {cardTitle}
              {cardDate ? (
                <>
                  &nbsp; — &nbsp;
                  {moment(cardDate).format("h:mm a").includes('3:30 am') ? moment(cardDate).format("h:mm a").replace('3:30 am', 'Time TBD') : moment(cardDate).format("h:mm a")}
                </>
              ) : null}
            </h1>
            {cart.isCardHasPendingInfo && !isInvoiceArchived ? <PendingLabel /> : null}
            {cart.cartDetails?.isServiceDeclined &&
            cart.cartDetails?.isServiceDeclined === SERVICE_STATE.DECLINED ? (
              <DeclinedLabel />
            ) : null}
            {isShowDeclineServiceButton ? (
              <button
                className="btn btn-primary btn-sm"
                onClick={async () => {
                  if (window.confirm("Are you sure you want to decline this service?")) {
                    const { data } = await client.mutate({
                      mutation: DECLINE_SERVICE,
                      variables: { packageDetailsId: cart.packageDetailsId },
                    });

                    if (data?.declineService) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: prev.orderDetails.map((item: any) => {
                            return item.packageDetailsId === cart.packageDetailsId
                              ? {
                                  ...item,
                                  cartDetails: {
                                    ...item.cartDetails,
                                    isServiceDeclined: SERVICE_STATE.DECLINED,
                                  },
                                }
                              : item;
                          }),
                        };
                      });
                      successMsg("Service Declined");
                    }
                  }
                }}
              >
                Decline service
              </button>
            ) : null}
          </Col>
          {isShowMissingInfoLabel ? (
            <Col>
              <div>
                {/* <MissingInfo
                  isShowExclamationIcon={true}
                  isShowNote={true}
                  isClickable={cart?.cartDetails.isPublished}
                  parsedCardData={parsedCardData}
                  setModalType={setModalType}
                  setPackageDetailsId={setPackageDetailsId}
                  setIsModalOpened={setIsModalOpened}
                  setAirportTransferType={setAirportTransferType}
                  setVegasLimoTransferType={setVegasLimoTransferType}
                  cart={cart}
                /> */}
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
      <CardBody className="pt-2">
        <RenderCardBody
          cart={cart}
          parsedCardData={parsedCardData}
          airportTransferType={airportTransferType}
          setAirportTransferType={setAirportTransferType}
          vegasLimoTransferType={vegasLimoTransferType}
          setVegasLimoTransferType={setVegasLimoTransferType}
          isCartInfoOpenedId={isCartInfoOpenedId}
          handleOpenDetails={handleOpenDetails}
          isInvoiceArchived={isInvoiceArchived}
          handleOpenLearnMore={handleOpenLearnMore}
          isLearnMoreOpenedId={isLearnMoreOpenedId}
        />
      </CardBody>
    </div>
  );
};

export default PackageDetailsCard;
