import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import "./select.scss";

const GooglePlaceAutocompleteInput = ({
  cardState,
  setCardState,
  placeName,
  placeValueName,
  placeLinkName,
  isInvalid,
  airportTransferType,
  vegasLimoTransferType,
}: any) => {
  const cardValue = cardState.cartData?.[placeName]
    ? {
        label: cardState.cartData?.[placeName],
        value: JSON.stringify(cardState.cartData?.[placeValueName]),
      }
    : "";

  const airportTransferValue = cardState.cartData?.[airportTransferType]?.[placeName]
    ? {
        label: cardState.cartData[airportTransferType][placeName],
        value: JSON.stringify(cardState.cartData[airportTransferType][placeValueName]),
      }
    : "";

    
    const vegasLimoTransferValue = cardState.cartData?.[vegasLimoTransferType]?.[placeName]
    ? {
        label: cardState.cartData[vegasLimoTransferType][placeName],
        value: JSON.stringify(cardState.cartData[vegasLimoTransferType][placeValueName]),
      }
    : "";

  const handleAirportTransferCardChange = (place: any) => {
    if (place?.label) {
      geocodeByAddress(place.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          const placeLink = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${place?.value?.place_id}`;

          setCardState((state: any) => {
            return {
              ...state,
              cartData: {
                ...state.cartData,
                [airportTransferType]: {
                  ...state.cartData[airportTransferType],
                  [placeName]: place.label.replaceAll("'", "’"),
                  [placeLinkName]: placeLink,
                  [placeValueName]: JSON.stringify(place).replaceAll("'", "’"),
                },
              },
            };
          });
        })
        .catch((error) => console.error(error));
    } else {
      setCardState((state: any) => {
        return {
          ...state,
          cartData: {
            ...state.cartData,
            [airportTransferType]: {
              ...state.cartData[airportTransferType],
              [placeName]: "",
              [placeLinkName]: "",
              [placeValueName]: "",
            },
          },
        };
      });
    }
  };
  
  const handleVegasLimoTransferCardChange = (place: any) => {
    if (setCardState) {
      setCardState((state: any) => {
        return {
          ...state,
          cartData: {
            ...state.cartData,
            [vegasLimoTransferType]: {
              ...state.cartData[vegasLimoTransferType],
              [placeName]: place?.label ? place.label.replaceAll("'", '’') : '',
            },
          },
        };
      });
    }
  };

  const handleCardChange = (place: any) => {
    if (place?.label) {
      geocodeByAddress(place.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          const placeLink = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${place?.value?.place_id}`;

          setCardState((state: any) => {
            return {
              ...state,
              cartData: {
                ...state.cartData,
                [placeName]: place.label.replaceAll("'", "’"),
                [placeLinkName]: placeLink,
                [placeValueName]: JSON.stringify(place).replaceAll("'", "’"),
              },
            };
          });
        })
        .catch((error) => console.error(error));
    } else {
      setCardState((state: any) => {
        return {
          ...state,
          cartData: {
            ...state.cartData,
            [placeName]: "",
            [placeLinkName]: "",
            [placeValueName]: "",
          },
        };
      });
    }
  };

  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyBotJSxfPV9-iBS1sfHgia__HWDlDlBVNg"
      selectProps={{
        value: vegasLimoTransferType ? vegasLimoTransferValue : airportTransferValue ? airportTransferValue : cardValue,
        isClearable: true,
        onChange: vegasLimoTransferType ? handleVegasLimoTransferCardChange : airportTransferValue ? handleAirportTransferCardChange : handleCardChange,
        styles: {
          control: (base: any) => ({
            ...base,
            margin: "0",
            width: "100%",
            borderRadius: "0.25rem",
            padding: "8px",
            borderColor: isInvalid ? "#ce4275" : "#dee2e6",
            boxShadow: "none",
            "&:hover": {
              borderColor: "none",
            },
          }),
        },
        placeholder: "Type and select",
      }}
    />
  );
};

export default GooglePlaceAutocompleteInput;
