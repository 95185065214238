import "./getTickets.scss";

const GetTickets = ({
  setIsModalOpened,
  isHasTicketsExceptEvent,
  isHasPdfEventTicket,
  isHasAcceptanceUrl,
  isButtonDisabled,
  isHasIdInUrlField,
}: any) => {
  return (
    <div className="get-tickets-container">
      <div className="ticket-holes"></div>
      <div>
        <h1 className="mt-2 main-label">Get Your Tickets!</h1>
      </div>
      <div className="ml-0 mr-0 d-flex justify-content-center">
        <button
          disabled={isButtonDisabled}
          onClick={async () => {
            setIsModalOpened(true);
          }}
          className="w-50"
        >
          {isHasPdfEventTicket || isHasAcceptanceUrl
            ? "Tickets Ready"
            : isHasTicketsExceptEvent || isHasIdInUrlField
            ? "View for More"
            : "Not Yet Available"}
        </button>
      </div>
      {isButtonDisabled ? (
        <div className="mt-4">
          <i className="fa-xl fa-regular fa-circle-exclamation mr-2"></i>
          <span className="info-label">
            You will receive an email or notification in your app once your tickets will be
            available
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default GetTickets;
