import { useApolloClient } from "@apollo/client";
import React from "react";
import { Button, Form, Input } from "reactstrap";
import { errorMsg, successMsg } from "../../cache/vars";
import {
  DECLINE_SERVICE,
  GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
  SAVE_STAGE_ONE_CARD,
} from "../../queries/vip-workflow";
import { SERVICE_STATE } from "../../utils/constants";
import TransferTypeButtons from "../Buttons/TransferTypeButtons";
import GooglePlaceAutocompleteInput from "../GooglePlaceAutocomplete/GooglePlaceAutocomplete";
import MissingInfo from "../labels/MissingInfo";
import "./stageOneCards.scss";

const AirportTransferGroupCard = ({
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
}: any) => {
  const client = useApolloClient();

  const [airportTransferType, setAirportTransferType] = React.useState<"transfer1" | "transfer2">(
    "transfer1",
  );
  const [cardState, setCardState] = React.useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {
      transfer1: {
        transferId: "1",
      },
      transfer2: {
        transferId: "2",
      },
    },
  });

  const [isFieldsValid, setIsFieldsValid] = React.useState({
    transfer1: {
      contactName1: true,
      contactNumber1: true,
      arrivalTime: true,
      airportName1: true,
      arrivalFlightAirline: true,
      flightNumber: true,
      departureFlightDate: true,
    },
    transfer2: {
      contactName2: true,
      contactNumber2: true,
      departureFlightDate: true,
      airportName2: true,
      departureFlightAirline: true,
      departureFlightNumber: true,
    },
  });

  return (
    <div className="card-container">
      <div className="card-header-container">
        <div className="d-flex align-items-center">
          <h2 className="card-title">Airport Transfers</h2>
        </div>
        <div>
          <MissingInfo isShowNote={false} isClickable={false} isShowExclamationIcon={true} />
        </div>
      </div>
      <div className="transfer-type-btn-container mb-4">
        <TransferTypeButtons
          airportTransferType={airportTransferType}
          setAirportTransferType={setAirportTransferType}
        />
      </div>
      <div className="card-body-container">
        <div className="card-info">
          <h3>Airport Transfer Service</h3>
          <span>
            {cardDescription}
          </span>
        </div>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            const airportTransfer1RequiredFields = [
              "contactName1",
              "contactNumber1",
              "arrivalTime",
              "airportName1",
              "arrivalFlightAirline",
              "flightNumber",
              "departureFlightDate",
            ];

            const airportTransfer2RequiredFields = [
              "contactName2",
              "contactNumber2",
              "departureFlightDate",
              "airportName2",
              "departureFlightAirline",
              "departureFlightNumber",
            ];

            const isTransfer1RequiredFieldsEmpty = airportTransfer1RequiredFields.some((el) => {
              return !cardState.cartData.transfer1[el];
            });

            const isTransfer2RequiredFieldsEmpty = airportTransfer2RequiredFields.some((el) => {
              return !cardState.cartData.transfer2[el];
            });

            if (isTransfer1RequiredFieldsEmpty || isTransfer2RequiredFieldsEmpty) {
              setIsFieldsValid({
                transfer1: {
                  contactName1: !!cardState.cartData.transfer1.contactName1,
                  contactNumber1: !!cardState.cartData.transfer1.contactNumber1,
                  arrivalTime: !!cardState.cartData.transfer1.arrivalTime,
                  airportName1: !!cardState.cartData.transfer1.airportName1,
                  arrivalFlightAirline: !!cardState.cartData.transfer1.arrivalFlightAirline,
                  flightNumber: !!cardState.cartData.transfer1.flightNumber,
                  departureFlightDate: !!cardState.cartData.transfer1.departureFlightDate,
                },
                transfer2: {
                  contactName2: !!cardState.cartData.transfer2.contactName2,
                  contactNumber2: !!cardState.cartData.transfer2.contactNumber2,
                  departureFlightDate: !!cardState.cartData.transfer2.departureFlightDate,
                  airportName2: !!cardState.cartData.transfer2.airportName2,
                  departureFlightAirline: !!cardState.cartData.transfer2.departureFlightAirline,
                  departureFlightNumber: !!cardState.cartData.transfer2.departureFlightNumber,
                },
              });

              errorMsg("Required Fields Should Not Be Empty");

              return;
            }

            const { data } = await client.mutate({
              mutation: SAVE_STAGE_ONE_CARD,
              variables: { editCartDetailsInput: cardState },
            });

            const filtersData = await client.query({
              query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
              variables: { invoiceId: decryptedInvoiceId },
              fetchPolicy: "no-cache",
            });

            if (data) {
              setPackageOrderWithDetails((prev: any) => {
                return {
                  ...prev,
                  orderDetails: [
                    ...prev.orderDetails.map((cart: any) => {
                      return cart.packageDetailsId === packageDetailsId
                        ? {
                            ...cart,
                            cartDetails: {
                              ...cart.cartDetails,
                              cardTitle: data?.saveStageOneCard.cardTitle,
                              cartId: data?.saveStageOneCard.cartId,
                              cartJsonData: data?.saveStageOneCard.cartJsonData,
                              isPublished: data?.saveStageOneCard.isPublished,
                              packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                            },
                          }
                        : cart;
                    }),
                  ],
                };
              });

              setMissingInfoCards(
                filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
              );

              successMsg("Saved");
            }
          }}
        >
          {airportTransferType === "transfer1" ? (
            <div className="inputs-container">
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.contactName1 ? "label-invalid" : ""}>
                  Contact Name
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.contactName1 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Contact Name"
                    name="contactName1"
                    invalid={!isFieldsValid.transfer1.contactName1}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.contactName1 || ""}
                  />
                  <span></span>
                </div>
              </div>
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.contactNumber1 ? "label-invalid" : ""}>
                  Contact Number
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.contactNumber1 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Contact Number"
                    name="contactNumber1"
                    invalid={!isFieldsValid.transfer1.contactNumber1}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.contactNumber1 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label
                  className={!isFieldsValid.transfer1.departureFlightDate ? "label-invalid" : ""}
                >
                  Departure Flight Date
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.departureFlightDate ? "input-invalid" : ""}
                    type="datetime-local"
                    placeholder="Departure Flight Date"
                    name="departureFlightDate"
                    invalid={!isFieldsValid.transfer1.departureFlightDate}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.departureFlightDate || ""}
                  />
                  <span></span>
                </div>
              </div>
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.arrivalTime ? "label-invalid" : ""}>
                  Arrival Flight Date
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.arrivalTime ? "input-invalid" : ""}
                    type="datetime-local"
                    placeholder="Arrival Flight Date"
                    name="arrivalTime"
                    invalid={!isFieldsValid.transfer1.arrivalTime}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.arrivalTime || ""}
                  />
                  <span></span>
                </div>
              </div>
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.airportName1 ? "label-invalid" : ""}>
                  Arrival Flight Airport
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.airportName1 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Arrival Flight Airport"
                    name="airportName1"
                    invalid={!isFieldsValid.transfer1.airportName1}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.airportName1 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label
                  className={!isFieldsValid.transfer1.arrivalFlightAirline ? "label-invalid" : ""}
                >
                  Arrival Flight Airline
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.arrivalFlightAirline ? "input-invalid" : ""}
                    type="text"
                    placeholder="Arrival Flight Airline"
                    name="arrivalFlightAirline"
                    invalid={!isFieldsValid.transfer1.arrivalFlightAirline}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.arrivalFlightAirline || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.flightNumber ? "label-invalid" : ""}>
                  Arrival Flight Number
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.flightNumber ? "input-invalid" : ""}
                    type="text"
                    placeholder="Arrival Flight Number"
                    name="flightNumber"
                    invalid={!isFieldsValid.transfer1.flightNumber}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.flightNumber || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label>Drop-off Address (if other than package hotel)</label>
                <div className="input-box">
                  <GooglePlaceAutocompleteInput
                    cardState={cardState}
                    setCardState={setCardState}
                    placeName={"dropOffPlaceName"}
                    placeValueName={"dropOffPlaceValue"}
                    placeLinkName={"dropOffPlaceLink"}
                    airportTransferType={airportTransferType}
                  />
                  <span></span>
                </div>
              </div>{" "}
            </div>
          ) : (
            <div className="inputs-container">
              <div className="input-container">
                <label className={!isFieldsValid.transfer2.contactName2 ? "label-invalid" : ""}>
                  Contact Name
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer2.contactName2 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Contact Name"
                    name="contactName2"
                    invalid={!isFieldsValid.transfer2.contactName2}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.contactName2 || ""}
                  />
                  <span></span>
                </div>
              </div>
              <div className="input-container">
                <label className={!isFieldsValid.transfer2.contactNumber2 ? "label-invalid" : ""}>
                  Contact Number
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer2.contactNumber2 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Contact Number"
                    name="contactNumber2"
                    invalid={!isFieldsValid.transfer2.contactNumber2}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.contactNumber2 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label>Pick-up Address (if other than package hotel)</label>
                <div className="input-box">
                  <GooglePlaceAutocompleteInput
                    cardState={cardState}
                    setCardState={setCardState}
                    placeName={"pickUpPlaceName"}
                    placeValueName={"pickUpPlaceValue"}
                    placeLinkName={"pickUpPlaceLink"}
                    airportTransferType={airportTransferType}
                  />
                  <span></span>
                </div>
              </div>
              <div className="input-container">
                <label
                  className={!isFieldsValid.transfer2.departureFlightDate ? "label-invalid" : ""}
                >
                  Departure Flight Date
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer2.departureFlightDate ? "input-invalid" : ""}
                    type="datetime-local"
                    placeholder="Departure Flight Date"
                    name="departureFlightDate"
                    invalid={!isFieldsValid.transfer2.departureFlightDate}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.departureFlightDate || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label className={!isFieldsValid.transfer2.airportName2 ? "label-invalid" : ""}>
                  Departure Flight Airport
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer2.airportName2 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Departure Flight Airport"
                    name="airportName2"
                    invalid={!isFieldsValid.transfer2.airportName2}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.airportName2 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label
                  className={!isFieldsValid.transfer2.departureFlightAirline ? "label-invalid" : ""}
                >
                  Departure Flight Airline
                </label>
                <div className="input-box">
                  <Input
                    className={
                      !isFieldsValid.transfer2.departureFlightAirline ? "input-invalid" : ""
                    }
                    type="text"
                    placeholder="Departure Flight Airline"
                    name="departureFlightAirline"
                    invalid={!isFieldsValid.transfer2.departureFlightAirline}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.departureFlightAirline || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label
                  className={!isFieldsValid.transfer2.departureFlightNumber ? "label-invalid" : ""}
                >
                  Departure Flight Number
                </label>
                <div className="input-box">
                  <Input
                    className={
                      !isFieldsValid.transfer2.departureFlightNumber ? "input-invalid" : ""
                    }
                    type="text"
                    placeholder="Departure Flight Number"
                    name="departureFlightNumber"
                    invalid={!isFieldsValid.transfer2.departureFlightNumber}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.departureFlightNumber || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
            </div>
          )}
          <div className="actions-container">
            <button
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                if (window.confirm("Are you sure you want to decline this service?")) {
                  const { data } = await client.mutate({
                    mutation: DECLINE_SERVICE,
                    variables: { packageDetailsId: packageDetailsId },
                  });

                  if (data?.declineService) {
                    setPackageOrderWithDetails((prev: any) => {
                      return {
                        ...prev,
                        orderDetails: prev.orderDetails.map((item: any) => {
                          return item.packageDetailsId === packageDetailsId
                            ? {
                                ...item,
                                cartDetails: {
                                  ...item.cartDetails,
                                  cartId: data?.declineService,
                                  isServiceDeclined: SERVICE_STATE.DECLINED,
                                },
                              }
                            : item;
                        }),
                      };
                    });
                    successMsg("Service Declined");
                  }
                }
              }}
              style={{ background: "none", border: "none", color: "#ea427f", fontWeight: "600" }}
            >
              Decline service
            </button>
            <Button className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AirportTransferGroupCard;
