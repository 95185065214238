import { useApolloClient } from "@apollo/client";
import React from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import CryptoJS from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
import moment from "moment";
import TicketsModal from "../../components/Modal/TicketsModal";
import {
  GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
  GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
} from "../../queries/vip-workflow";
import { CARD_TYPES_TICKETS, CRYPTO_JS_SECRET, FULLY_EDITABLE_CARDS } from "../../utils/constants";
import CartsContainer from "./CartsContainer";
import GetTickets from "./GetTickets";
import "./packageInfo.scss";
import PackageMenu from "./PackageMenu";
import Timer from "./Timer";
import PackageDisclaimer from "./PackageDisclaimer";
import { IPackageOrderWithDetails } from "../../utils/interfaces";
import Smartlook from "smartlook-client";
import { getUserId } from "../../utils/services";
import { useHistory } from "react-router-dom";

const PackageInfoPage = ({
  match: {
    params: { invoiceSecret },
  },
}: any) => {
  const client = useApolloClient();
  const history = useHistory();

  const slashesReturnedToEncryptedInvoiceId = invoiceSecret.replace(
    /HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61/g,
    "/",
  );

  const decryptedInvoiceId = Number.parseFloat(
    CryptoJS.decrypt(slashesReturnedToEncryptedInvoiceId.toString(), CRYPTO_JS_SECRET).toString(
      enc,
    ),
  );
  const [airportTransferType, setAirportTransferType] = React.useState<"transfer1" | "transfer2">(
    "transfer1",
  );
  
  const [vegasLimoTransferType, setvegasLimoTransferType] = React.useState<"transfer1" | "transfer2">(
    "transfer1",
  );

  const [packageDetailsId, setPackageDetailsId] = React.useState<number | null>(null);
  const [isEditDetailsModalOpened, setIsisEditDetailsModalOpened] = React.useState(false);
  const [isTicketsModalOPened, setIsTicketsModalOpened] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [modalType, setModalType] = React.useState("");
  const [activeCardsFilter, setActiveCardsFilter] = React.useState("");
  const [packageOrderWithDetails, setPackageOrderWithDetails] =
    React.useState<IPackageOrderWithDetails | null>(null);
  const [isCartInfoOpenedId, setIsCartInfoOpenedId] = React.useState<number[]>([]);
  const [isLearnMoreOpenedId, setIsLearnMoreOpenedId] = React.useState<number[]>([]);
  const [availableFilters, setAvailableFilters] = React.useState([]);
  const [missingInfoCards, setMissingInfoCards] = React.useState([]);
  const [packageData, setPackageData] = React.useState<any>({});
  const [isInvoiceArchived, setIsInvoiceArchived] = React.useState(false);

  const getPackageData = async () => {
    setLoading(true);

    const { data } = await client.query({
      query: GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
      variables: { invoiceId: decryptedInvoiceId, orderDetailsFilter: activeCardsFilter },
      fetchPolicy: "no-cache",
    });

    const filtersData = await client.query({
      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
      variables: { invoiceId: decryptedInvoiceId },
      fetchPolicy: "cache-first",
    });

    const isInvoiceArchived =
      moment(data?.getOnePackageWithDetailsForClient?.eventDate, "MMMM Do YYYY, h:mm a") < moment();

    setPackageOrderWithDetails(data?.getOnePackageWithDetailsForClient);
    setAvailableFilters(filtersData.data?.getAvailableFiltersForPackageInfo.filtersList);

    setPackageData(data);
    setIsInvoiceArchived(isInvoiceArchived);

    setMissingInfoCards(filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor);
    setLoading(false);
  };

  React.useEffect(() => {
    if (Number.isNaN(decryptedInvoiceId)) {
      history.push("/not-found");
    } else {
      getPackageData();
    }
  }, [activeCardsFilter]);

  React.useEffect(() => {
    Smartlook.init("357cdd7d112927200da07d414da3ca51050e52d7", { region: "us", cookies: false });
    Smartlook.record({ numbers: true, forms: true });
    Smartlook.identify(getUserId(), {
      invoiceId: decryptedInvoiceId,
    });
    Smartlook.track("packageInfo", { invoiceId: decryptedInvoiceId });
  }, []);

  const handleOpenDetails = (e: any, packageDetailsId: number) => {
    e.preventDefault();
    if (isCartInfoOpenedId.includes(packageDetailsId)) {
      setIsCartInfoOpenedId((prev) => {
        return prev.filter((el) => el !== packageDetailsId);
      });
    } else {
      setIsCartInfoOpenedId((prev) => {
        return [...prev, packageDetailsId];
      });
    }
  };

  const handleOpenLearnMore = (e: any, packageDetailsId: number) => {
    e.preventDefault();
    if (isLearnMoreOpenedId.includes(packageDetailsId)) {
      setIsLearnMoreOpenedId((prev) => {
        return prev.filter((el) => el !== packageDetailsId);
      });
    } else {
      setIsLearnMoreOpenedId((prev) => {
        return [...prev, packageDetailsId];
      });
    }
  };
  console.log(
    (!packageOrderWithDetails?.isTicketOneFromOneTicketType &&
      !packageOrderWithDetails?.isHasPdfEventTicket &&
      !packageOrderWithDetails?.isHasTicketsExceptEvent) ||
      (packageOrderWithDetails?.isTicketOneFromOneTicketType &&
        !packageOrderWithDetails?.isHasAcceptanceUrl &&
        !packageOrderWithDetails?.isHasIdInUrlField &&
        !packageOrderWithDetails?.isHasTicketsExceptEvent &&
        packageOrderWithDetails.isTicketsSent) ||
      !(
        packageOrderWithDetails?.isTicketOneFromOneTicketType &&
        packageOrderWithDetails?.isHasPdfEventTicket
      ),
    !packageOrderWithDetails?.isTicketOneFromOneTicketType &&
      !packageOrderWithDetails?.isHasPdfEventTicket &&
      !packageOrderWithDetails?.isHasTicketsExceptEvent,
    packageOrderWithDetails?.isTicketOneFromOneTicketType &&
      !packageOrderWithDetails?.isHasAcceptanceUrl &&
      !packageOrderWithDetails?.isHasIdInUrlField &&
      !packageOrderWithDetails?.isHasTicketsExceptEvent &&
      packageOrderWithDetails.isTicketsSent,
    !(
      packageOrderWithDetails?.isTicketOneFromOneTicketType &&
      packageOrderWithDetails?.isHasPdfEventTicket
    ),
  );
  return (
    <>
      <Timer />
      <TicketsModal
        isModalOpened={isTicketsModalOPened}
        setIsModalOpened={setIsTicketsModalOpened}
        isShowEventTicketsAdditionalMessage={
          (packageOrderWithDetails?.isHasTicketsExceptEvent ||
            packageOrderWithDetails?.isHasIdInUrlField
            ) &&
          !(
            packageOrderWithDetails?.isHasAcceptanceUrl ||
            packageOrderWithDetails?.isHasPdfEventTicket
          )
        }
        invoiceId={decryptedInvoiceId}
      />
      <Container className="pt-4 pb-4" fluid>
        <Row>
          <Col sm="4">
            <PackageMenu
              activeCardsFilter={activeCardsFilter}
              setActiveCardsFilter={setActiveCardsFilter}
              availableFilters={availableFilters}
              packageOrder={packageData?.getOnePackageWithDetailsForClient}
              missingInfoCards={missingInfoCards}
            />
            {(!packageOrderWithDetails?.isTicketOneFromOneTicketType &&
              !packageOrderWithDetails?.isHasPdfEventTicket &&
              !packageOrderWithDetails?.isHasTicketsExceptEvent &&
              !packageOrderWithDetails?.isHasAcceptanceUrl) ||
            (packageOrderWithDetails?.isTicketOneFromOneTicketType &&
              !packageOrderWithDetails?.isHasAcceptanceUrl &&
              !packageOrderWithDetails?.isHasIdInUrlField &&
              !packageOrderWithDetails?.isHasTicketsExceptEvent &&
              packageOrderWithDetails.isTicketsSent &&
              !packageOrderWithDetails?.isHasPdfEventTicket) ? null : (
              <GetTickets
                setIsModalOpened={setIsTicketsModalOpened}
                isHasTicketsExceptEvent={packageOrderWithDetails?.isHasTicketsExceptEvent}
                isHasPdfEventTicket={packageOrderWithDetails?.isHasPdfEventTicket}
                isHasAcceptanceUrl={packageOrderWithDetails?.isHasAcceptanceUrl}
                isHasIdInUrlField={packageOrderWithDetails?.isHasIdInUrlField}
                isButtonDisabled={
                  !packageOrderWithDetails?.isHasPdfEventTicket &&
                  !packageOrderWithDetails?.isHasTicketsExceptEvent &&
                  !packageOrderWithDetails?.isHasAcceptanceUrl &&
                  !packageOrderWithDetails?.isHasIdInUrlField
                }
              />
            )}
          </Col>
          <Col sm="8" className="pl-4 pr-4 pt-4" id="carts-container">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="info" size="lg" />
              </div>
            ) : packageOrderWithDetails ? (
              <>
                <PackageDisclaimer orderDetails={packageOrderWithDetails?.orderDetails} />
                <div className="scrollable-cards-container">
                  <CartsContainer
                    packageOrderWithDetails={packageOrderWithDetails}
                    setModalType={setModalType}
                    setPackageDetailsId={setPackageDetailsId}
                    setIsModalOpened={setIsisEditDetailsModalOpened}
                    airportTransferType={airportTransferType}
                    setAirportTransferType={setAirportTransferType}
                    vegasLimoTransferType={vegasLimoTransferType}
                    setVegasLimoTransferType={setvegasLimoTransferType}
                    isCartInfoOpenedId={isCartInfoOpenedId}
                    handleOpenDetails={handleOpenDetails}
                    handleOpenLearnMore={handleOpenLearnMore}
                    isLearnMoreOpenedId={isLearnMoreOpenedId}
                    isInvoiceArchived={isInvoiceArchived}
                    setPackageOrderWithDetails={setPackageOrderWithDetails}
                    decryptedInvoiceId={decryptedInvoiceId}
                    setMissingInfoCards={setMissingInfoCards}
                  />
                </div>
              </>
            ) : (
              <h1>Items Not Found</h1>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PackageInfoPage;
