import { useApolloClient } from "@apollo/client";
import React from "react";
import { Button, Form, Input } from "reactstrap";
import { errorMsg, successMsg } from "../../cache/vars";
import {
  DECLINE_SERVICE,
  GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
  SAVE_STAGE_ONE_CARD,
} from "../../queries/vip-workflow";
import { SERVICE_STATE } from "../../utils/constants";
import MissingInfo from "../labels/MissingInfo";
import "./stageOneCards.scss";

const CityPassGroupCard = ({
  packageDetailsId,
  setPackageOrderWithDetails,
  attendeeQty,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
}: any) => {
  const client = useApolloClient();

  const [cardState, setCardState] = React.useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: { attendeeNames: [...new Array(attendeeQty).fill({ attendeeName: "" })] },
  });

  const [isFieldsValid, setIsFieldsValid] = React.useState<number[]>([]);

  return (
    <div className="card-container">
      <div className="card-header-container">
        <div className="d-flex align-items-center">
          <h2 className="card-title">City Pass Details</h2>
        </div>
        <div>
          <MissingInfo isShowNote={false} isClickable={false} isShowExclamationIcon={true} />
        </div>
      </div>
      <div className="card-body-container">
        <div className="card-info">
          <h3>City Pass</h3>
          <span>
            {cardDescription}
          </span>
        </div>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();

            const emptyRequiredFieldsIds = cardState.cartData.attendeeNames.reduce(
              (prev: any, el: { attendeeName: string }, id: number) => {
                if (!el.attendeeName) {
                  prev.push(id);
                }

                return prev;
              },
              [],
            );

            if (emptyRequiredFieldsIds.length) {
              setIsFieldsValid((prev: any) => {
                return [...emptyRequiredFieldsIds];
              });

              errorMsg("Required Fields Should Not Be Empty");

              return;
            }

            const { data } = await client.mutate({
              mutation: SAVE_STAGE_ONE_CARD,
              variables: { editCartDetailsInput: cardState },
            });

            const filtersData = await client.query({
              query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
              variables: { invoiceId: decryptedInvoiceId },
              fetchPolicy: "no-cache",
            });

            if (data) {
              setPackageOrderWithDetails((prev: any) => {
                return {
                  ...prev,
                  orderDetails: [
                    ...prev.orderDetails.map((cart: any) => {
                      return cart.packageDetailsId === packageDetailsId
                        ? {
                            ...cart,
                            cartDetails: {
                              ...cart.cartDetails,
                              cardTitle: data?.saveStageOneCard.cardTitle,
                              cartId: data?.saveStageOneCard.cartId,
                              cartJsonData: data?.saveStageOneCard.cartJsonData,
                              isPublished: data?.saveStageOneCard.isPublished,
                              packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                            },
                          }
                        : cart;
                    }),
                  ],
                };
              });

              setMissingInfoCards(
                filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
              );

              successMsg("Saved");
            }
          }}
        >
          <div className="inputs-container">
            {cardState.cartData.attendeeNames.map((item: { attendeeName: string }, id: number) => {
              return (
                <div key={id} className="input-container">
                  <label className={isFieldsValid.includes(id) ? "label-invalid" : ""}>
                    Attendee Name
                  </label>
                  <div className="input-box">
                    <Input
                      className={isFieldsValid.includes(id) ? "input-invalid" : ""}
                      type="text"
                      placeholder="Attendee Name"
                      name="attendeeName"
                      invalid={isFieldsValid.includes(id)}
                      value={item.attendeeName}
                      onChange={(e) => {
                        setCardState((prev: any) => {
                          return {
                            ...prev,
                            cartData: {
                              ...prev.cartData,
                              attendeeNames: [
                                ...prev.cartData.attendeeNames.map((attendee: any, index: number) =>
                                  index === id
                                    ? { ...attendee, attendeeName: e.target.value }
                                    : attendee,
                                ),
                              ],
                            },
                          };
                        });
                      }}
                    />
                    <span></span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="actions-container">
            <button
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                if (window.confirm("Are you sure you want to decline this service?")) {
                  const { data } = await client.mutate({
                    mutation: DECLINE_SERVICE,
                    variables: { packageDetailsId: packageDetailsId },
                  });

                  if (data?.declineService) {
                    setPackageOrderWithDetails((prev: any) => {
                      return {
                        ...prev,
                        orderDetails: prev.orderDetails.map((item: any) => {
                          return item.packageDetailsId === packageDetailsId
                            ? {
                                ...item,
                                cartDetails: {
                                  ...item.cartDetails,
                                  cartId: data?.declineService,
                                  isServiceDeclined: SERVICE_STATE.DECLINED,
                                },
                              }
                            : item;
                        }),
                      };
                    });
                    successMsg("Service Declined");
                  }
                }
              }}
              style={{ background: "none", border: "none", color: "#ea427f", fontWeight: "600" }}
            >
              Decline service
            </button>
            <Button className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CityPassGroupCard;
