import { useApolloClient } from "@apollo/client";
import React from "react";
import { Button, Form, Input } from "reactstrap";
import { errorMsg, successMsg } from "../../cache/vars";
import {
  DECLINE_SERVICE,
  GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
  SAVE_STAGE_ONE_CARD,
} from "../../queries/vip-workflow";
import { SERVICE_STATE } from "../../utils/constants";
import GooglePlaceAutocompleteInput from "../GooglePlaceAutocomplete/GooglePlaceAutocomplete";
import MissingInfo from "../labels/MissingInfo";
import "./stageOneCards.scss";

const LimoGroupCard = ({
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
}: any) => {
  const client = useApolloClient();

  const [cardState, setCardState] = React.useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {},
  });

  const [isFieldsValid, setIsFieldsValid] = React.useState({
    contactName: true,
    contactNumber: true,
    pickUpPlaceName: true,
    dropOffPlaceName: true,
  });

  return (
    <div className="card-container">
      <div className="card-header-container">
        <div className="d-flex align-items-center">
          <h2 className="card-title">Transportation Services</h2>
        </div>
        <div>
          <MissingInfo isShowNote={false} isClickable={false} isShowExclamationIcon={true} />
        </div>
      </div>
      <div className="card-body-container">
        <div className="card-info">
          <h3>7 Hour Limousine Service</h3>
          <span>
            {cardDescription}
          </span>
        </div>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            const requiredFields = [
              "contactName",
              "contactNumber",
              "pickUpPlaceName",
              "dropOffPlaceName",
            ];

            const isRequiredFieldsEmpty = requiredFields.some((el) => {
              return !cardState.cartData[el];
            });

            if (isRequiredFieldsEmpty) {
              setIsFieldsValid({
                contactName: !!cardState.cartData?.contactName,
                contactNumber: !!cardState.cartData?.contactNumber,
                pickUpPlaceName: !!cardState.cartData?.pickUpPlaceName,
                dropOffPlaceName: !!cardState.cartData?.dropOffPlaceName,
              });

              errorMsg("Required Fields Should Not Be Empty");

              return;
            }

            const { data } = await client.mutate({
              mutation: SAVE_STAGE_ONE_CARD,
              variables: { editCartDetailsInput: cardState },
            });

            const filtersData = await client.query({
              query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
              variables: { invoiceId: decryptedInvoiceId },
              fetchPolicy: "no-cache",
            });

            if (data) {
              setPackageOrderWithDetails((prev: any) => {
                return {
                  ...prev,
                  orderDetails: [
                    ...prev.orderDetails.map((cart: any) => {
                      return cart.packageDetailsId === packageDetailsId
                        ? {
                            ...cart,
                            cartDetails: {
                              ...cart.cartDetails,
                              cardTitle: data?.saveStageOneCard.cardTitle,
                              cartId: data?.saveStageOneCard.cartId,
                              cartJsonData: data?.saveStageOneCard.cartJsonData,
                              isPublished: data?.saveStageOneCard.isPublished,
                              packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                            },
                          }
                        : cart;
                    }),
                  ],
                };
              });

              setMissingInfoCards(
                filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
              );

              successMsg("Saved");
            }
          }}
        >
          <div className="inputs-container">
            <div className="input-container">
              <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                Contact Name
              </label>
              <div className="input-box">
                <Input
                  className={!isFieldsValid.contactName ? "input-invalid" : ""}
                  type="text"
                  placeholder="Contact Name"
                  name="contactName"
                  invalid={!isFieldsValid.contactName}
                  onChange={(e) => {
                    setCardState((prev: any) => {
                      return {
                        ...prev,
                        cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                      };
                    });
                  }}
                />
                <span></span>
              </div>
            </div>
            <div className="input-container">
              <label className={!isFieldsValid.contactNumber ? "label-invalid" : ""}>
                Contact Number
              </label>
              <div className="input-box">
                <Input
                  className={!isFieldsValid.contactNumber ? "input-invalid" : ""}
                  type="text"
                  placeholder="Contact Number"
                  name="contactNumber"
                  invalid={!isFieldsValid.contactNumber}
                  onChange={(e) => {
                    setCardState((prev: any) => {
                      return {
                        ...prev,
                        cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                      };
                    });
                  }}
                />
                <span></span>
              </div>
            </div>{" "}
            <div className="input-container">
              <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                Pick-up Address
              </label>
              <div className="input-box">
                <GooglePlaceAutocompleteInput
                  cardState={cardState}
                  setCardState={setCardState}
                  placeName={"pickUpPlaceName"}
                  placeValueName={"pickUpPlaceValue"}
                  placeLinkName={"pickUpPlaceLink"}
                  isInvalid={!isFieldsValid.pickUpPlaceName}
                />
                <span></span>
              </div>
            </div>
            <div className="input-container">
              <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                Drop-off Address
              </label>
              <div className="input-box">
                <GooglePlaceAutocompleteInput
                  cardState={cardState}
                  setCardState={setCardState}
                  placeName={"dropOffPlaceName"}
                  placeValueName={"dropOffPlaceValue"}
                  placeLinkName={"dropOffPlaceLink"}
                  isInvalid={!isFieldsValid.dropOffPlaceName}
                />
                <span></span>
              </div>
            </div>
            <div className="input-container">
              <label className="form-control-label">Celebration</label>
              <Input
                type="text"
                placeholder="Are you celebrating a special occasion?"
                name="celebration"
                onChange={(e) => {
                  setCardState((prev: any) => {
                    return {
                      ...prev,
                      cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                    };
                  });
                }}
              />
            </div>
          </div>
          <div className="actions-container">
            <button
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                if (window.confirm("Are you sure you want to decline this service?")) {
                  const { data } = await client.mutate({
                    mutation: DECLINE_SERVICE,
                    variables: { packageDetailsId: packageDetailsId },
                  });
                  if (data?.declineService) {
                    setPackageOrderWithDetails((prev: any) => {
                      return {
                        ...prev,
                        orderDetails: prev.orderDetails.map((item: any) => {
                          return item.packageDetailsId === packageDetailsId
                            ? {
                                ...item,
                                cartDetails: {
                                  ...item.cartDetails,
                                  cartId: data?.declineService,
                                  isServiceDeclined: SERVICE_STATE.DECLINED,
                                },
                              }
                            : item;
                        }),
                      };
                    });
                    successMsg("Service Declined");
                  }
                }
              }}
              style={{ background: "none", border: "none", color: "#ea427f", fontWeight: "600" }}
            >
              Decline service
            </button>
            <Button className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LimoGroupCard;
