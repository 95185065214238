import { useApolloClient } from "@apollo/client";
import React from "react";
import { Button, Form, Input } from "reactstrap";
import { errorMsg, successMsg } from "../../cache/vars";
import {
  DECLINE_SERVICE,
  GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
  SAVE_STAGE_ONE_CARD,
} from "../../queries/vip-workflow";
import { SERVICE_STATE } from "../../utils/constants";
import MissingInfo from "../labels/MissingInfo";
import "./stageOneCards.scss";

const HotelGroupCard = ({
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
}: any) => {
  const client = useApolloClient();

  const [cardState, setCardState] = React.useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {},
  });

  const [isFieldsValid, setIsFieldsValid] = React.useState({
    checkInName: true,
    contactNumber: true,
    bedsNumber: true,
  });

  return (
    <div className="card-container">
      <div className="card-header-container">
        <div className="d-flex align-items-center">
          <h2 className="card-title">Hotel Details</h2>
        </div>
        <div>
          <MissingInfo isShowNote={false} isClickable={false} isShowExclamationIcon={true} />
        </div>
      </div>
      <div className="card-body-container">
        <div className="card-info">
          <h3>Hotel Accomodation</h3>
          <span>
            {cardDescription}
          </span>
        </div>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            const requiredFields = ["checkInName", "contactNumber", "bedsNumber"];

            const isRequiredFieldsEmpty = requiredFields.some((el) => {
              return !cardState.cartData[el];
            });

            if (isRequiredFieldsEmpty) {
              setIsFieldsValid({
                checkInName: !!cardState.cartData?.checkInName,
                contactNumber: !!cardState.cartData?.contactNumber,
                bedsNumber: !!cardState.cartData?.bedsNumber,
              });

              errorMsg("Required Fields Should Not Be Empty");

              return;
            }

            const { data } = await client.mutate({
              mutation: SAVE_STAGE_ONE_CARD,
              variables: { editCartDetailsInput: cardState },
            });

            const filtersData = await client.query({
              query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
              variables: { invoiceId: decryptedInvoiceId },
              fetchPolicy: "no-cache",
            });

            if (data) {
              setPackageOrderWithDetails((prev: any) => {
                return {
                  ...prev,
                  orderDetails: [
                    ...prev.orderDetails.map((cart: any) => {
                      return cart.packageDetailsId === packageDetailsId
                        ? {
                            ...cart,
                            cartDetails: {
                              ...cart.cartDetails,
                              cardTitle: data?.saveStageOneCard.cardTitle,
                              cartId: data?.saveStageOneCard.cartId,
                              cartJsonData: data?.saveStageOneCard.cartJsonData,
                              isPublished: data?.saveStageOneCard.isPublished,
                              packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                            },
                          }
                        : cart;
                    }),
                  ],
                };
              });

              setMissingInfoCards(
                filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
              );

              successMsg("Saved");
            }
          }}
        >
          <div className="inputs-container">
            <div className="input-container">
              <label className={!isFieldsValid.checkInName ? "label-invalid" : ""}>
                Check-in Name
              </label>
              <div className="input-box">
                <Input
                  className={!isFieldsValid.checkInName ? "input-invalid" : ""}
                  type="text"
                  placeholder="Check-in name for your hotel reservation"
                  name="checkInName"
                  invalid={!isFieldsValid.checkInName}
                  onChange={(e) => {
                    setCardState((prev: any) => {
                      return {
                        ...prev,
                        cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                      };
                    });
                  }}
                />
                <span></span>
              </div>
            </div>
            <div className="input-container">
              <label className={!isFieldsValid.contactNumber ? "label-invalid" : ""}>
                Contact Number
              </label>
              <div className="input-box">
                <Input
                  className={!isFieldsValid.contactNumber ? "input-invalid" : ""}
                  type="text"
                  placeholder="Contact Number"
                  name="contactNumber"
                  invalid={!isFieldsValid.contactNumber}
                  onChange={(e) => {
                    setCardState((prev: any) => {
                      return {
                        ...prev,
                        cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                      };
                    });
                  }}
                />
                <span></span>
              </div>
            </div>{" "}
            <div className="input-container">
              <label className={!isFieldsValid.bedsNumber ? "label-invalid" : ""}>
                Bed Request
              </label>
              <div className="input-box">
                <Input
                  className={!isFieldsValid.bedsNumber ? "input-invalid" : ""}
                  type="number"
                  min={1}
                  placeholder="Bed Request (1 bed or 2 beds)"
                  name="bedsNumber"
                  invalid={!isFieldsValid.bedsNumber}
                  onChange={(e) => {
                    setCardState((prev: any) => {
                      return {
                        ...prev,
                        cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                      };
                    });
                  }}
                />
                <span></span>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <span className="font-italic">
              Keep in mind bed requests are not guaranteed but we will place the request with the
              hotel on your behalf. This package includes a check-in the day before the event and a
              check-out the day after the event. If you need alternative dates or additional nights,
              we will do our best to accommodate but please note, there may be additional fees.
              Additional nights are based on availability so please let us know as soon as possible.
            </span>
          </div>
          <div className="inputs-container">
            <div className="input-container">
              <label>Additional Request</label>
              <div className="input-box">
                <Input
                  type="text"
                  placeholder="Additional Requests"
                  name="additionalRequests"
                  onChange={(e) => {
                    setCardState((prev: any) => {
                      return {
                        ...prev,
                        cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                      };
                    });
                  }}
                />
                <span></span>
              </div>
            </div>
            <div className="input-container">
              <label>Celebration</label>
              <div className="input-box">
                <Input
                  type="text"
                  placeholder="Are you celebrating a special occasion?"
                  name="celebration"
                  onChange={(e) => {
                    setCardState((prev: any) => {
                      return {
                        ...prev,
                        cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                      };
                    });
                  }}
                />
                <span></span>
              </div>
            </div>{" "}
          </div>
          <div className="actions-container">
            <button
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                if (window.confirm("Are you sure you want to decline this service?")) {
                  const { data } = await client.mutate({
                    mutation: DECLINE_SERVICE,
                    variables: { packageDetailsId: packageDetailsId },
                  });
                  if (data?.declineService) {
                    setPackageOrderWithDetails((prev: any) => {
                      return {
                        ...prev,
                        orderDetails: prev.orderDetails.map((item: any) => {
                          return item.packageDetailsId === packageDetailsId
                            ? {
                                ...item,
                                cartDetails: {
                                  ...item.cartDetails,
                                  cartId: data?.declineService,
                                  isServiceDeclined: SERVICE_STATE.DECLINED,
                                },
                              }
                            : item;
                        }),
                      };
                    });
                    successMsg("Service Declined");
                  }
                }
              }}
              style={{ background: "none", border: "none", color: "#ea427f", fontWeight: "600" }}
            >
              Decline service
            </button>
            <Button className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HotelGroupCard;
