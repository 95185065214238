import { CART_FILTERS, CARTS_BY_FILTERS } from "../../utils/constants";
import "./cardFilter.scss";

const PackageInfoCartFilters = ({
  activeCardsFilter,
  setActiveCardsFilter,
  availableFilters,
  missingInfoCards,
}: any) => {
  return (
    <div className="card-filters-container">
      {Object.entries(CART_FILTERS).map(([key, value], id) => {
        if (
          (CARTS_BY_FILTERS[key as keyof typeof CARTS_BY_FILTERS] &&
          CARTS_BY_FILTERS[key as keyof typeof CARTS_BY_FILTERS].every((el: any) => {
            return !availableFilters.includes(el);
          })) || key === 'vegasLimoTransfer'
        ) {
          return;
        }
        
        return (
          <div key={id}>
            <button
              className={`filter-btn 
              ${
                CARTS_BY_FILTERS[key as keyof typeof CARTS_BY_FILTERS]?.some((el) =>
                  missingInfoCards.includes(el),
                )
                  ? "missing-info-sign"
                  : ""
              }
            ${
              value === activeCardsFilter || (!activeCardsFilter && value === "All")
                ? "active-filter"
                : ""
            }`}
              onClick={(e) => {
                e.preventDefault();

                const textElement = document.getElementById("carts-container");

                textElement?.scrollIntoView({ behavior: "smooth" });

                if (key === "all") {
                  setActiveCardsFilter("");
                } else {
                  setActiveCardsFilter(value);
                }
              }}
              key={id}
            >
              {value}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default PackageInfoCartFilters;
