import { Card, CardBody, CardHeader, Row } from "reactstrap";
import PackageInfoActionButtons from "./PackageInfoActionButtons";
import PackageInfoCartFilters from "./PackageInfoCartFilters";
import moment from "moment";
import "./packageMenu.scss";

const PackageMenu = ({
  activeCardsFilter,
  setActiveCardsFilter,
  availableFilters,
  packageOrder,
  missingInfoCards,
}: any) => {
  const address = packageOrder
    ? `${packageOrder?.venueCity}, ${packageOrder?.venue} (${packageOrder?.venueAddress})`
    : "";
  const eventDates =
    moment(packageOrder?.startDate, "MMMM Do YYYY").toLocaleString() ===
    moment(packageOrder?.endDate, "MMMM Do YYYY").toLocaleString()
      ? packageOrder?.startDate
      : packageOrder?.startDate + " - " + packageOrder?.endDate;

  const invoiceIdLabel = `Invoice Id: ${packageOrder?.invoiceId || ""}`;
  return (
    <div className="menu-container">
      <div className="default-font">
        <div>
          <span className="font-lg mb-0">{packageOrder?.eventName}</span>
        </div>
        <div className="mt-1">
          <span className="font-sm">{packageOrder?.eventDate?.includes('3:30 am') ? packageOrder.eventDate.replace('3:30 am', 'Time TBD') : packageOrder?.eventDate}</span>
        </div>
        <div className="mt-4">
          <span className="font-md">{address}</span>
        </div>
      </div>
      <div className="line mt-4 mb-4"></div>
      <div className="d-flex flex-column default-font">
        <div className="mb-3">
          <span className="font-md">
            {packageOrder?.section} {packageOrder?.row}
          </span>
        </div>
        <div className="text-white">
          <span className="font-md">
            <i className="fa fa-lg fa-light fa-calendar"></i>&nbsp; {eventDates}
          </span>
        </div>
        <div className="mt-3 mb-4 text-white font-sm">
          <div className="mb-2">
            <span>{packageOrder?.recipient}</span>
          </div>
          <div className="mb-2">
            <span>{packageOrder?.recipientEmail}</span>
          </div>
          <div className="d-flex flex-column">
            <span>{invoiceIdLabel}</span>
            <span className="m-0">
              Order ID:&nbsp;
              {packageOrder?.externalPo || "-"}
            </span>
          </div>
        </div>
        {/* <PackageInfoActionButtons /> */}
        <PackageInfoCartFilters
          activeCardsFilter={activeCardsFilter}
          setActiveCardsFilter={setActiveCardsFilter}
          availableFilters={availableFilters}
          missingInfoCards={missingInfoCards}
        />
      </div>
    </div>
  );
};

export default PackageMenu;
