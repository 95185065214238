import { Navbar, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { checkToken, getUserRole } from "../../utils/services";

const JWT_KEY = "jwt";

const Header: React.FC = () => {
  const history = useHistory();

  const logout = () => {
    document.cookie = `${JWT_KEY}=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    history.push("/login");
  };

  return (
    <Navbar
      color={getUserRole() === "BROKER" ? "default" : "white"}
      className={`navbar-top navbar-expand navbar-light bg-secondary pt-3 ${
        getUserRole() === "BROKER" ? "" : "pb-0"
      }`}
    >
      <Container fluid style={{ display: "flex", alignItems: "center" }}>
        <h2
          style={{
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: "38px",
            display: "flex",
            alignItems: "center",
            color: getUserRole() === "BROKER" ? "#ffffff" : "#1B204C",
            margin: "0",
          }}
        >
          elevate
        </h2>
        {checkToken() ? (
          <button
            onClick={(e: any) => {
              logout();
            }}
            style={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "20px",
              color: getUserRole() === "BROKER" ? "#ffffff" : "#000000",

              border: "none",
              background: "none",
            }}
          >
            <i className="fa fa-xl fa-solid fa-right-from-bracket mr-2"></i>
            <span>Logout</span>
          </button>
        ) : (
          <Link
            to="/login"
            style={{
              background: "none",
              border: "2px solid #1B204C",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "20px",
              color: "#000000",

              borderRadius: "4px",
              padding: "4px 6px 4px 6px",
            }}
          >
            Log In
          </Link>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
