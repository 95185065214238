import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { successMsg } from "../../cache/vars";

const UploadedUrls = ({
  ticket,
  index,
}: any) => {
  return (
    <div
        className="d-flex align-items-center mt-2 justify-content-between"
        key={index}
    >
        <div style={{ width: "70%" }}>
            <i className="fa fa-thin fa-globe mr-2"></i>
            <a href={ticket.eTicketLink} target="_blank" rel="noreferrer" style={{ overflowWrap: "anywhere" }}>{ticket.eTicketLink}</a>
        </div>
        
        {/* {ticket.deliveryStatus === "UPLOADED" || ticket.deliveryStatus === "EMAILED" ? (
        <div>
            <button
                className="btn btn-primary btn-sm"
                onClick={(e) => {
                e.preventDefault();

                navigator.clipboard.writeText(ticket.eTicketLink);
                successMsg('Copied to clickboard');
                }}
            >
                Copy URL
            </button>
        </div>
        ) : null} */}
    </div>
    );
    }

export default UploadedUrls;
