import { Row, Col } from "reactstrap";
import {
  CARD_TYPES_TICKETS,
  CARD_FORM_SCHEMA,
  DEFAULT_CARD_CONFIG,
  FULLY_EDITABLE_CARDS,
} from "../../utils/constants";
import Cart from "./Card";

const RenderCardBody = ({
  cart,
  parsedCardData,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  isCartInfoOpenedId,
  handleOpenDetails,
  isInvoiceArchived,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
}: any) => {
  const linkMdParser = (value: string) => {
    const regex = /^(.*?)\s*\(?\s*(https?:\/\/[^\s)]+)\s*\)?$/;
   
    const matches = value.match(regex);

    if (matches) {
      const label = matches[1].trim() || matches[2];
      const url = matches[2];
      return {
        label: label || value,
        url: url || value
      };
    }

    return {
      label: value,
      url: value
    };
  };
   
  return (
    <>
      {CARD_TYPES_TICKETS.includes(cart.group) ? (
        Object.entries(CARD_FORM_SCHEMA).map(([key, value]: any, id) => {
          if (cart.group === key) {
            if (key === "Airport Transfer") {
              const cartSchemaConfig = parsedCardData?.transferId
                ? value[`transfer${parsedCardData?.transferId}` as keyof typeof value]
                : value[airportTransferType as keyof typeof value];
              return (
                <Cart
                  cartSchemaConfig={cartSchemaConfig}
                  cartData={cart}
                  airportTransferType={airportTransferType}
                  setAirportTransferType={setAirportTransferType}
                  dataKey={key}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  parsedCardDetails={parsedCardData?.[airportTransferType as keyof typeof value]}
                  isInvoiceArchived={isInvoiceArchived}
                  cartInfoId={id}
                  key={id}
                />
              );
            }
            
            if (key === "Vegas Limo Transfer") {
              const cartSchemaConfig = parsedCardData?.transferId
                ? value[`transfer${parsedCardData?.transferId}` as keyof typeof value]
                : value[vegasLimoTransferType as keyof typeof value];
              return (
                <Cart
                  cartSchemaConfig={cartSchemaConfig}
                  cartData={cart}
                  vegasLimoTransferType={vegasLimoTransferType}
                  setVegasLimoTransferType={setVegasLimoTransferType}
                  dataKey={key}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  parsedCardDetails={parsedCardData?.[vegasLimoTransferType as keyof typeof value]}
                  isInvoiceArchived={isInvoiceArchived}
                  cartInfoId={id}
                  key={id}
                />
              );
            }

            if (CARD_TYPES_TICKETS.includes(cart?.group) && !cart?.cartDetails.isPublished) {
              return (
                <Cart
                  cartSchemaConfig={DEFAULT_CARD_CONFIG}
                  parsedCardDetails={cart}
                  isInvoiceArchived={isInvoiceArchived}
                  cartInfoId={id}
                  key={id}
                />
              );
            }

            return (
              <Cart
                cartSchemaConfig={value}
                cartData={cart}
                airportTransferType={airportTransferType}
                setAirportTransferType={setAirportTransferType}
                vegasLimoTransferType={vegasLimoTransferType}
                setVegasLimoTransferType={setVegasLimoTransferType}
                dataKey={key}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={parsedCardData}
                cartInfoId={id}
                handleOpenLearnMore={handleOpenLearnMore}
                isLearnMoreOpenedId={isLearnMoreOpenedId}
                isInvoiceArchived={isInvoiceArchived}
                key={id}
              />
            );
          }
        })
      ) : FULLY_EDITABLE_CARDS.includes(cart.group) && parsedCardData ? (
        <div>
          {cart?.cartDetails.isPublished ? (
            <>
              <div>
                {Array.isArray(parsedCardData) && cart.group !== "VIP Club Access"
                  ? parsedCardData.map((field: any, id: number) => {
                      return Object.entries(field).map(([key, value]: any) => {
                        if (key.toLowerCase() === "link" && !value) return;
                        return (
                          <Row
                            style={{
                              // paddingLeft: "15px",
                              paddingRight: "15px",
                              marginTop: id === 0 ? "" : "6px",
                            }}
                            key={id}
                          >
                            <Col
                              xs="6"
                              className="d-flex"
                              // style={{ paddingLeft: id === 0 ? "" : "3.25rem" }}
                            >
                              {id === 0 ? (
                                <div>
                                  <i
                                    className="fa fa-lg fa-regular fa-notes"
                                    style={{
                                      width: "28px",
                                      textAlign: "center",
                                      marginRight: "0.5rem",
                                    }}
                                  />
                                </div>
                              ) : null}
                              <span
                                className="text-capitalize"
                                style={{
                                  marginLeft: id === 0 ? "0px" : "36px",
                                }}
                              >
                                {key
                                  .split(/(?=[A-Z]|[0-9])/)
                                  .join(" ")
                                  .toLowerCase()}
                              </span>
                            </Col>
                            <Col>
                            {key.toLowerCase() === 'link' && cart.group === 'Other'
                              ? <a href={linkMdParser(value).url} rel="noreferrer" target="_blank">{linkMdParser(value).label}</a>
                              : <span className="text-capitalize">{value ? value : "-"}</span>}
                            </Col>
                          </Row>
                        );
                      });
                    })
                  : parsedCardData
                      .filter((el: any) => {
                        const key = Object.keys(el)[0];
                        if (key === "ticketsSent" || key === "ticketsSentDate") {
                          return false;
                        }
                        return true;
                      })
                      .map((field: any, id: number) => {
                        return Object.entries(field).map(([key, value]: any) => {
                          return (
                            <Row
                              style={{
                                // paddingLeft: "15px",
                                paddingRight: "15px",
                                marginTop: id === 0 ? "" : "6px",
                              }}
                              key={id}
                            >
                              <Col
                                xs="6"
                                className="d-flex"
                                // style={{ paddingLeft: id === 0 ? "" : "3.25rem" }}
                              >
                                {id === 0 ? (
                                  <div>
                                    <i
                                      className="fa fa-lg fa-regular fa-notes"
                                      style={{
                                        width: "28px",
                                        textAlign: "center",
                                        marginRight: "0.5rem",
                                      }}
                                    />
                                  </div>
                                ) : null}
                                <span
                                  className="text-capitalize"
                                  style={{
                                    marginLeft: id === 0 ? "0px" : "36px",
                                  }}
                                >
                                  {key
                                    .split(/(?=[A-Z]|[0-9])/)
                                    .join(" ")
                                    .toLowerCase()}
                                </span>
                              </Col>
                              <Col xs="6" className="d-flex">
                                <div className="d-inline-block text-truncate">
                                  {(() => {
                                  const parsedValue = linkMdParser(value);
                                  if (parsedValue.url.startsWith("http://") || parsedValue.url.startsWith("https://")) {
                                    return (
                                      <a className="text-truncate" href={parsedValue.url} target="_blank" rel="noopener noreferrer">
                                        {parsedValue.label}
                                      </a>
                                    );
                                  }
                                  return <span className={`text-truncate ${field.restaurantPlaceLink ? '' : 'text-capitalize'}`}>{value ? value : "-"}</span>;
                                  })()}
                                </div>
                              </Col>
                            </Row>
                          );
                        });
                      })}
              </div>
            </>
          ) : (
            <Cart
              cartSchemaConfig={DEFAULT_CARD_CONFIG}
              parsedCardDetails={cart}
              isInvoiceArchived={isInvoiceArchived}
            />
          )}
        </div>
      ) : (
        <Cart cartSchemaConfig={DEFAULT_CARD_CONFIG} parsedCardDetails={cart} />
      )}
    </>
  );
};

export default RenderCardBody;
