import { useApolloClient } from "@apollo/client";
import React from "react";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { successMsg } from "../../cache/vars";
import { DECLINE_SERVICE, SAVE_STAGE_ONE_CARD } from "../../queries/vip-workflow";
import { SERVICE_STATE } from "../../utils/constants";
import MissingInfo from "../labels/MissingInfo";
import "./stageOneCards.scss";

const DinnerGroupCard = ({ packageDetailsId, setPackageOrderWithDetails, cardDescription }: any) => {
  const client = useApolloClient();

  const [cardState, setCardState] = React.useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: { recipientName: "" },
  });

  return (
    <div className="card-container">
      <div className="card-header-container">
        <div className="d-flex align-items-center">
          <h2 className="card-title">Pre-show Hospitality</h2>
        </div>
      </div>
      <div className="card-body-container">
        <div className="card-info">
          <h3>Pre-show Hospitality</h3>
          <span>
            {cardDescription}
          </span>
        </div>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            const { data } = await client.mutate({
              mutation: SAVE_STAGE_ONE_CARD,
              variables: { editCartDetailsInput: cardState },
            });

            if (data) {
              setPackageOrderWithDetails((prev: any) => {
                return {
                  ...prev,
                  orderDetails: [
                    ...prev.orderDetails.map((cart: any) => {
                      return cart.packageDetailsId === packageDetailsId
                        ? {
                            ...cart,
                            cartDetails: {
                              ...cart.cartDetails,
                              cardTitle: data?.saveStageOneCard.cardTitle,
                              cartId: data?.saveStageOneCard.cartId,
                              cartJsonData: data?.saveStageOneCard.cartJsonData,
                              isPublished: data?.saveStageOneCard.isPublished,
                              packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                            },
                          }
                        : cart;
                    }),
                  ],
                };
              });

              successMsg("Saved");
            }
          }}
        >
          <div className="inputs-container">
            <div className="input-container">
              <label>Alternative Name</label>
              <div className="input-box">
                <Input
                  type="text"
                  placeholder="Alternative Name"
                  name="alternativeName"
                  value={cardState.cartData.alternativeName}
                  onChange={(e) => {
                    setCardState((prev: any) => {
                      return {
                        ...prev,
                        cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                      };
                    });
                  }}
                />
                <span></span>
              </div>
            </div>
          </div>
          <div className="actions-container">
            <button
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                if (window.confirm("Are you sure you want to decline this service?")) {
                  const { data } = await client.mutate({
                    mutation: DECLINE_SERVICE,
                    variables: { packageDetailsId: packageDetailsId },
                  });

                  if (data?.declineService) {
                    setPackageOrderWithDetails((prev: any) => {
                      return {
                        ...prev,
                        orderDetails: prev.orderDetails.map((item: any) => {
                          return item.packageDetailsId === packageDetailsId
                            ? {
                                ...item,
                                cartDetails: {
                                  ...item.cartDetails,
                                  cartId: data?.declineService,
                                  isServiceDeclined: SERVICE_STATE.DECLINED,
                                },
                              }
                            : item;
                        }),
                      };
                    });
                    successMsg("Service Declined");
                  }
                }
              }}
              style={{ background: "none", border: "none", color: "#ea427f", fontWeight: "600" }}
            >
              Decline service
            </button>
            <Button className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DinnerGroupCard;
