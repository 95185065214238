import { Row } from "reactstrap";
import { editableCartTypes, FULLY_EDITABLE_CARDS, STAGE_ONE_CARDS } from "../../utils/constants";

const STAGE_ONE_PACKAGE_DISCLAIMER = `Thank you for purchasing a package! We look forward to making this a memorable purchase for you. Below is an outline of your package purchase. Some packages require that you answer questions that will assist us with properly fulfilling your request. Remember, your event tickets will be delivered electronically and details will become available closer to your event date.`;

const STAGE_TWO_DISCLAIMER = `Thank you for purchasing a package! We look forward to making this a memorable purchase for you. Below is an outline of your package purchase. Remember, your event tickets will be delivered electronically and details will become available closer to your event date.`;

const PackageDisclaimer = ({ orderDetails }: any) => {
  const isAllCardsPublished = orderDetails
    ?.filter(
      (el: any) => editableCartTypes.includes(el.group) || FULLY_EDITABLE_CARDS.includes(el.group),
    )
    .every((el: any) => el.cartDetails?.isPublished);

  const isPackageHasStageOneCards = orderDetails
    ?.filter((el: any) => STAGE_ONE_CARDS.includes(el.group))
    .some((el: any) => !el?.cartDetails?.cartId);

  return isAllCardsPublished ? null : (
    <Row className="mb-2 ml-0 mr-0">
      <span className="font-italic">
        {isPackageHasStageOneCards ? STAGE_ONE_PACKAGE_DISCLAIMER : STAGE_TWO_DISCLAIMER}
      </span>
    </Row>
  );
};

export default PackageDisclaimer;
