import { useApolloClient, useLazyQuery } from "@apollo/client";
import { Modal, Spinner } from "reactstrap";
import {
  GET_TICKETS_LIST_FOR_TICKETS_MODAL,
} from "../../queries/vip-workflow";
import "./editPackageDetailsModal.scss";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import UploadedTickets from "./UploadedTickets";
import UploadedUrls from "./UploadedUrls";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TicketsModal = ({
  isModalOpened,
  setIsModalOpened,
  isShowEventTicketsAdditionalMessage,
  invoiceId,
}: any) => {

  const [pdf, setPdf] = React.useState("");
  const [numPages, setNumPages] = React.useState<null | number>(null);
  const [pdfScale, setPdfScale] = React.useState(1.25);

  const [getTickets, ticketsData] = useLazyQuery(GET_TICKETS_LIST_FOR_TICKETS_MODAL, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (isModalOpened) {
      getTickets({ variables: { invoiceId } });
    }
  }, [isModalOpened]);

  const modalWidth = document?.getElementById("ticketsModal")?.offsetWidth;

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  React.useEffect(() => {
    if (modalWidth && modalWidth < 800 && pdfScale !== 0.75) {
      setPdfScale(0.75);
    }

    if (modalWidth && modalWidth >= 800 && pdfScale !== 1.25) {
      setPdfScale(1.25);
    }

    if (modalWidth && modalWidth < 400 && pdfScale !== 0.59) {
      setPdfScale(0.59);
    }
  }, [modalWidth]);

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setIsModalOpened(false);
        setPdf("");
      }}
      className="modal-dialog-centered modal-secondary"
      size="lg"
      fade={false}
      id="ticketsModal"
    >
      <div className="modal-body pb-3">
        <div className="d-flex justify-content-between ">
          <div>
            <h1>TICKETS</h1>
          </div>
          <div>
            <button
              style={{ background: "none", border: "none" }}
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpened(false);
                setPdf("");
              }}
            >
              <i className="fas fa-times" aria-hidden="true" />
            </button>
          </div>
        </div>
        {pdf ? (
          <div>
            <button
              className="btn btn-md p-1"
              onClick={() => {
                setPdf("");
              }}
            >
              &larr; Back To Tickets List
            </button>
            <div className="mt-2">
              <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={pdfScale} />
                ))}
              </Document>
            </div>
          </div>
        ) : ticketsData.loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="info" size="lg" />
          </div>
        ) : (
          <div>
            {isShowEventTicketsAdditionalMessage ? (
              <div className="mt-3">
                <div>
                  <h2 className="text-capitalize">Event Tickets</h2>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-2 justify-content-between">
                    <div style={{ width: "70%" }}>
                      <i className="fa fa-thin fa-paperclip mr-2"></i>
                      <span style={{ fontStyle: "italic" }}>
                        You will receive an email or notification in your app once your Event
                        Tickets will be available. They may not be accessible here.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {ticketsData.data?.getTicketsListForTicketsModalForClient &&
              Object.entries(
                JSON.parse(ticketsData.data?.getTicketsListForTicketsModalForClient),
              ).map(([key, value]: any, id) => {
                if (value && value.length) {
                  const ticketsForUploadedTickets = value.filter((ticket: any) => !ticket?.eTicketLink?.length);
                  const ticketsForUploadedUrls = value.filter((ticket: any) => ticket?.eTicketLink?.length);
                  return (
                    <div className="mt-3" key={id}>
                      <div>
                        <h2 className="text-capitalize">
                          {key === "Event Details" ? "Event Tickets" : key}
                        </h2>
                      </div>
                      <div>
                          {ticketsForUploadedTickets.map((ticket: any, index: number) => (
                            <UploadedTickets ticket={ticket} index={index} setPdf={setPdf} />
                          ))}
                          {ticketsForUploadedUrls.map((ticket: any, index: number) => (
                            <UploadedUrls ticket={ticket} index={index} />
                          ))}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TicketsModal;
