import { useApolloClient } from "@apollo/client";
import {
  GET_BASE_64_PDF,
  GET_PDF_DOWNLOAD_LINK,
} from "../../queries/vip-workflow";
import "./editPackageDetailsModal.scss";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadedTickets = ({
  ticket,
  index,
  setPdf,
}: any) => {
  const client = useApolloClient();

  return (
    <div
        className="d-flex mt-2 justify-content-between"
        key={index}
    >
        <div style={{ width: "70%" }}>
            <i className="fa fa-thin fa-paperclip mr-2"></i>
            {ticket.deliveryStatus === "UPLOADED" ? (
                <span style={{ overflowWrap: "anywhere" }}>
                {ticket.fileName
                    ? ticket.fileName
                    : ticket.key?.replace(/[0-9]*-/, "")}
                </span>
            ) : ticket.deliveryStatus === "EMAILED" ? (
                <a href={ticket.eTicketLink}>Click here to accept your tickets</a>
            ) : (
                <span style={{ fontStyle: "italic" }}>
                Will transfer on 14 days before event
                </span>
            )}
        </div>
        
        {ticket.deliveryStatus === "UPLOADED" ? (
        <div>
            <button
            className="btn btn-primary btn-sm"
            onClick={async () => {
                const { data } = await client.query({
                query: GET_PDF_DOWNLOAD_LINK,
                variables: { id: ticket.id },
                fetchPolicy: "no-cache",
                });

                const link = document.createElement("a");
                link.href = data?.getPdfDownloadLink;
                link.download = ticket.key;

                document.body.appendChild(link);
                link.click();
                //@ts-expect-error
                link.parentNode.removeChild(link);
            }}
            >
            <i className="fa fa-lg fa-thin fa-download mr-2" />
            Download
            </button>
            <button
            className="btn btn-primary btn-sm"
            onClick={async () => {
                const { data } = await client.query({
                query: GET_BASE_64_PDF,
                variables: { id: ticket.id },
                fetchPolicy: "no-cache",
                });

                setPdf(`data:application/pdf;base64,${data?.getPdfFile}`);
            }}
            >
            Preview
            </button>
        </div>
        ) : null}
    </div>
  );
};

export default UploadedTickets;
