import { useApolloClient } from "@apollo/client";
import React from "react";
import { Button, Form, Input } from "reactstrap";
import GooglePlaceAutocompleteInput from "../GooglePlaceAutocomplete/GooglePlaceAutocomplete";
import { errorMsg, successMsg } from "../../cache/vars";
import {
  DECLINE_SERVICE,
  GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
  SAVE_STAGE_ONE_CARD,
} from "../../queries/vip-workflow";
import { SERVICE_STATE } from "../../utils/constants";
import TransferTypeButtons from "../Buttons/TransferTypeButtons";
import MissingInfo from "../labels/MissingInfo";
import "./stageOneCards.scss";

const VegasLimoTransferGroupCard = ({
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
}: any) => {
  const client = useApolloClient();

  const [vegasLimoTransferType, setVegasLimoTransferType] = React.useState<"transfer1" | "transfer2">(
    "transfer1",
  );
  const [cardState, setCardState] = React.useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {
      transfer1: {
        transferId: "1",
      },
      transfer2: {
        transferId: "2",
      },
    },
  });

  const [isFieldsValid, setIsFieldsValid] = React.useState({
    transfer1: {
      contactName1: true,
      contactNumber1: true,
      pickUpName1: true,
      pickUpAddress1: true,
    },
    transfer2: {
      contactName2: true,
      contactNumber2: true,
      dropOffName2: true,
      dropOffAddress2: true,
    },
  });

  return (
    <div className="card-container">
      <div className="card-header-container">
        <div className="d-flex align-items-center">
          <h2 className="card-title">Transportation Services</h2>
        </div>
        <div>
          <MissingInfo isShowNote={false} isClickable={false} isShowExclamationIcon={true} />
        </div>
      </div>
      <div className="transfer-type-btn-container mb-4">
        <TransferTypeButtons
          vegasLimoTransferType={vegasLimoTransferType}
          setVegasLimoTransferType={setVegasLimoTransferType}
        />
      </div>
      <div className="card-body-container">
        <div className="card-info">
          <h3>Las Vegas Limousine Transfer Service</h3>
          <span>
            {cardDescription}
          </span>
        </div>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            const vegasLimoTransfer1RequiredFields = [
              "pickUpName1",
              "pickUpAddress1",
              "contactName1",
              "contactNumber1",
            ];

            const vegasLimoTransfer2RequiredFields = [
              "dropOffName2",
              "dropOffAddress2",
              "contactName2",
              "contactNumber2",
            ];

            const isTransfer1RequiredFieldsEmpty = vegasLimoTransfer1RequiredFields.some((el) => {
              return !cardState.cartData.transfer1[el];
            });

            const isTransfer2RequiredFieldsEmpty = vegasLimoTransfer2RequiredFields.some((el) => {
              return !cardState.cartData.transfer2[el];
            });

            if (isTransfer1RequiredFieldsEmpty || isTransfer2RequiredFieldsEmpty) {
              setIsFieldsValid({
                transfer1: {
                  contactName1: !!cardState.cartData.transfer1.contactName1,
                  contactNumber1: !!cardState.cartData.transfer1.contactNumber1,
                  pickUpName1: !!cardState.cartData.transfer1.pickUpName1,
                  pickUpAddress1: !!cardState.cartData.transfer1.pickUpAddress1,
                },
                transfer2: {
                  contactName2: !!cardState.cartData.transfer2.contactName2,
                  contactNumber2: !!cardState.cartData.transfer2.contactNumber2,
                  dropOffName2: !!cardState.cartData.transfer2.dropOffName2,
                  dropOffAddress2: !!cardState.cartData.transfer2.dropOffAddress2,
                },
              });

              errorMsg("Required Fields Should Not Be Empty");

              return;
            }

            const { data } = await client.mutate({
              mutation: SAVE_STAGE_ONE_CARD,
              variables: { editCartDetailsInput: cardState },
            });

            const filtersData = await client.query({
              query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
              variables: { invoiceId: decryptedInvoiceId },
              fetchPolicy: "no-cache",
            });

            if (data) {
              setPackageOrderWithDetails((prev: any) => {
                return {
                  ...prev,
                  orderDetails: [
                    ...prev.orderDetails.map((cart: any) => {
                      return cart.packageDetailsId === packageDetailsId
                        ? {
                            ...cart,
                            cartDetails: {
                              ...cart.cartDetails,
                              cardTitle: data?.saveStageOneCard.cardTitle,
                              cartId: data?.saveStageOneCard.cartId,
                              cartJsonData: data?.saveStageOneCard.cartJsonData,
                              isPublished: data?.saveStageOneCard.isPublished,
                              packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                            },
                          }
                        : cart;
                    }),
                  ],
                };
              });
              
              setMissingInfoCards(
                filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
              );

              successMsg("Saved");
            }
          }}
        >
          {vegasLimoTransferType === "transfer1" ? (
            <div className="inputs-container">
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.contactName1 ? "label-invalid" : ""}>
                  Contact Name
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.contactName1 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Contact Name"
                    name="contactName1"
                    invalid={!isFieldsValid.transfer1.contactName1}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.contactName1 || ""}
                  />
                  <span></span>
                </div>
              </div>
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.contactNumber1 ? "label-invalid" : ""}>
                  Contact Number
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.contactNumber1 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Contact Number"
                    name="contactNumber1"
                    invalid={!isFieldsValid.transfer1.contactNumber1}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.contactNumber1 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.pickUpName1 ? "label-invalid" : ""}>Pick-up Name</label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer1.pickUpName1 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Pick-up Name"
                    name="pickUpName1"
                    invalid={!isFieldsValid.transfer1.pickUpName1}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.pickUpName1 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label className={!isFieldsValid.transfer1.pickUpAddress1 ? "label-invalid": ""}>Pick-up Address</label>
                <div className="input-box">
                  <GooglePlaceAutocompleteInput
                    cardState={cardState}
                    setCardState={setCardState}
                    placeName={"pickUpAddress1"}
                    placeValueName={"pickUpAddress1"}
                    vegasLimoTransferType={vegasLimoTransferType}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label>Celebration</label>
                <div className="input-box">
                  <Input
                    type="text"
                    placeholder="Are you celebrating a special occasion?"
                    name="celebration1"
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer1: {
                              ...prev.cartData.transfer1,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer1.celebration1 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
            </div>
          ) : (
            <div className="inputs-container">
              <div className="input-container">
                <label className={!isFieldsValid.transfer2.contactName2 ? "label-invalid" : ""}>
                  Contact Name
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer2.contactName2 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Contact Name"
                    name="contactName2"
                    invalid={!isFieldsValid.transfer2.contactName2}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.contactName2 || ""}
                  />
                  <span></span>
                </div>
              </div>
              <div className="input-container">
                <label className={!isFieldsValid.transfer2.contactNumber2 ? "label-invalid" : ""}>
                  Contact Number
                </label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer2.contactNumber2 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Contact Number"
                    name="contactNumber2"
                    invalid={!isFieldsValid.transfer2.contactNumber2}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.contactNumber2 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label className={!isFieldsValid.transfer2.dropOffName2 ? "label-invalid": ""}>Drop-off Name</label>
                <div className="input-box">
                  <Input
                    className={!isFieldsValid.transfer2.dropOffName2 ? "input-invalid" : ""}
                    type="text"
                    placeholder="Drop-off Name"
                    name="dropOffName2"
                    invalid={!isFieldsValid.transfer2.dropOffName2}
                    onChange={(e) => {
                      setCardState((prev: any) => {
                        return {
                          ...prev,
                          cartData: {
                            ...prev.cartData,
                            transfer2: {
                              ...prev.cartData.transfer2,
                              [e.target.name]: e.target.value,
                            },
                          },
                        };
                      });
                    }}
                    value={cardState.cartData.transfer2.dropOffName2 || ""}
                  />
                  <span></span>
                </div>
              </div>{" "}
              <div className="input-container">
                <label className={!isFieldsValid.transfer2.dropOffAddress2 ? "label-invalid": ""}>Drop-off Address</label>
                <div className="input-box">
                  <GooglePlaceAutocompleteInput
                    cardState={cardState}
                    setCardState={setCardState}
                    placeName={"dropOffAddress2"}
                    placeValueName={"dropOffAddress2"}
                    vegasLimoTransferType={vegasLimoTransferType}
                  />
                  <span></span>
                </div>
              </div>{" "}
            </div>
          )}
          <div className="actions-container">
            <button
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                if (window.confirm("Are you sure you want to decline this service?")) {
                  const { data } = await client.mutate({
                    mutation: DECLINE_SERVICE,
                    variables: { packageDetailsId: packageDetailsId },
                  });

                  if (data?.declineService) {
                    setPackageOrderWithDetails((prev: any) => {
                      return {
                        ...prev,
                        orderDetails: prev.orderDetails.map((item: any) => {
                          return item.packageDetailsId === packageDetailsId
                            ? {
                                ...item,
                                cartDetails: {
                                  ...item.cartDetails,
                                  cartId: data?.declineService,
                                  isServiceDeclined: SERVICE_STATE.DECLINED,
                                },
                              }
                            : item;
                        }),
                      };
                    });
                    successMsg("Service Declined");
                  }
                }
              }}
              style={{ background: "none", border: "none", color: "#ea427f", fontWeight: "600" }}
            >
              Decline service
            </button>
            <Button className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default VegasLimoTransferGroupCard;
